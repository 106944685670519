.Calendar {
  display: grid; }

.CalendarColumn {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  align-content: start;
  .DateTitle {
    font-size: 12px;
    font-weight: 600; } }
