.FilterPanel {
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .Title {
    font-size: 12px;
    font-weight: 500;
    margin-right: 12px; }
  .DateField, .CityField {
    display: flex;
    align-items: center; }
  .CitiesControll {
    margin-bottom: 0; }
  .CityField {
    margin-right: 24px; } }
