.CitiesControll {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .Button {
    &:not(:last-child) {
      margin-right: 12px; } } }
.CategoryPrice-PopUp-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 32px; }

.PriceContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px; }
