.ClientHistory {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px; }
.HistoryEventTile {
  padding: 8px;
  background-color: var(--background);
  border-radius: 8px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px;
  .Date {
    color: var(--darkGray);
    font-size: 12px;
    margin-top: 4px; }
  .Content {
    .Title {
      font-size: 14px;
      font-weight: 500;
      color: var(--black); } }
  .CheckoutEvent {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px; }
  .DownloadFile {
    display: flex;
    align-items: center;
    color: var(--accent);
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px var(--lightGray) solid;
    cursor: pointer;
    .Icon-Container {
      width: 24px;
      height: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: var(--accent);
      margin-right: 8px;
      border-radius: 100%;
      margin-left: 8px; }
    .Cancel {
      color: var(--notyColor);
      margin-left: 12px;
      font-size: 18px; } } }
