.ClientsManager {
  display: grid;
  grid-template-columns: 750px 1fr;
  grid-gap: 24px;
  align-items: start;
  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }
