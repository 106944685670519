.Nav {
  width: 64px;
  height: 100vh;
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 16px 0px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
.NavBody {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }
.NavElement {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  justify-items: center;
  cursor: pointer;
  position: relative;
  .Counter {
    font-size: 10px;
    background-color: red;
    width: 18px;
    height: 18px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    color: #fff;
    border-radius: 100%; }
  .NavElement-Icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: var(--darkGray);
    font-size: 18px; }
  .Title {
    color: var(--black);
    font-weight: 500; }
  &:hover {
    .NavElement-Icon {
      background-color: var(--accent);
      color: #fff; } } }
.NavElement-Active {
  .NavElement-Icon {
    background-color: var(--accent);
    color: #fff; } }
