.SubmitFormButtons {
  padding: 16px 0px;
  width: 100%;
  border-top: 1px var(--inputBorder) solid;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .Button {
    &:not(:last-child) {
      margin-right: 16px; } } }
.SubmitFormButtons_justify_start {
  justify-content: flex-start; }

.SubmitFormButtons_justify_center {
  justify-content: center; }

.SubmitFormButtons_justify_end {
  justify-content: flex-end; }

.SubmitFormButtons-StatusMessage {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 36px;
  .StatusMessage {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out; } }

.SubmitFormButtons-StatusMessage_show {
  .StatusMessage {
    transform: translateX(0%); } }
.SubmitFormButtons-LatestUpdate {
  flex-shrink: 0;
  .UserNameWithAvatar {
    display: flex;
    .UserAvatar {
      margin-left: 12px;
      margin-right: 0; }
    .UserData {
      display: flex;
      flex-direction: column;
      text-align: right;
      .UserName {
        font-size: 13px;
        font-weight: 600; }
      .UpdateDate {
        color: var(--descriptionDark); } } } }
