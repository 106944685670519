.SearchUser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .RegNewButton {
    text-align: center;
    margin-top: 24px; }
  .Title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px; }

  .SearchData-Container {
    border: 1px var(--lightGray) solid;
    // margin-top: 24px
    padding: 16px;
    border-radius: 8px;
    .EmptyResult {
      color: var(--darkGray);
      text-align: center; }
    .SearchResult-Tile {
      padding: 8px;
      cursor: pointer;
      .UserName {
        font-size: 16px;
        font-weight: 500; }
      .Contacts {
        color: var(--darkGray); }
      &:not(:last-child) {
        border-bottom: 1px var(--lightGray) solid; }
      &:hover {
        background: var(--background); } } }
  .PinnedUser {
    padding: 16px;
    background-color: var(--background);
    border-radius: 8px;
    border: 1px var(--lightGray) solid;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 16px;
    .UserName {
      font-size: 16px;
      font-weight: 500; }
    .Contacts {
      color: var(--darkGray); }
    .Icon-Container {
      cursor: pointer;
      background-color: var(--failColor);
      color: var(--notyColor);
      text-align: center;
      border-radius: 100%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center; } } }
