.RouteComponent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  h5 {
    margin-top: 12px;
    font-size: 24px;
    line-height: 24px; } }
.RouteComponent-Header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.RouteComponent-Body {
  border: 1px var(--lightGray) solid;
  border-radius: 8px;
  padding: 16px; }
