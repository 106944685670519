.TileManager {
  max-width: 600px;
  .Buttons {
    .Button {
      &:first-child {
        margin-right: 24px; } } }
  .TileManager-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 12px;
    .Fieldset {
      display: grid;
      grid-template-columns: 1fr 16px;
      grid-gap: 24px;
      .Button {
        margin-top: 24px; }
      .Fields {
        padding: 16px;
        background-color: var(--background);
        .Field-Select [value="Подтвержденная запись"] {
          background-color: var(--successColor); }
        .Field-Select [value="Предварительная запись"] {
          background-color: var(--failColor); } } } }

  .Fields {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 24px; } }
.TypeButtons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px; }
.TypeButton {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: var(--background);
  border-radius: 5px;
  cursor: pointer;
  .Icon {
    margin-right: 12px; }
  &:hover {
    background-color: var(--accent);
    color: #fff; } }
.TypeButton_status_active {
  background-color: var(--accent);
  color: #fff; }
