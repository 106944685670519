.ClientForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px;
  padding-top: 32px;
  .Site-StatusMessage {
    grid-column: 1/-1;
    margin-bottom: 16px; }
  .Buttons {
    grid-column: 1/-1; } }
.ClientRegInsert {}
