.EventManager {
  max-width: 600px;
  .Buttons {
    .Button {
      &:first-child {
        margin-right: 24px; } } } }
.EventManager-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 12px;
  .TimeFields {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px; } }
