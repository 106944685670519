.DoctorsPanel {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  padding-right: 12px;
  border-right: 1px #E2E6EB solid;
  align-content: start; }
.Checkout-DoctorTile {
  padding: 8px;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 12px;
  border-bottom: 1px #E2E6EB solid;
  cursor: pointer;
  border-radius: 4px;
  .Avatar {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    position: relative;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      border-radius: 4px; }
    .DoctorAvatar {
      font-size: 18px;
      background-color: var(--background);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--accent); }
    .ScheduleTag {
      font-size: 10px;
      background-color: red;
      width: 18px;
      height: 18px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      right: -9px;
      color: #fff;
      border-radius: 100%; } }
  .Title {
    font-size: 14px;
    color: var(--accent3); }
  &:hover {
    background-color: var(--accent);
    border-bottom: 1px var(--accent) solid;
    .Title {
      color: #fff; } } }
.Checkout-DoctorTile_theme_active {
  background-color: var(--accent);
  border-bottom: 1px var(--accent) solid;
  .Title {
    color: #fff; } }
