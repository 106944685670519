.EventTile {
  padding: 8px;
  border: 1px #E2E6EB solid;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  position: relative;

  .GroupLabel {
    background-color: #c01ded;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 4px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 12px;
    color: #fff; }
  .Date {
    font-size: 14px;
    color: #067B9C; }
  .Title {
    font-size: 12px;
    font-weight: 700; }
  .Description {
    font-size: 10px;
    font-weight: 700;
    color: #067B9C;
    .Icon {
      margin-right: 4px; } }
  .Client {
    font-size: 14px;
    font-weight: 400; }
  .PhoneNumber {
    font-weight: 500; }
  .TileDetails {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .Tag {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px; }
  .OptionsTag {
    background-color: #DEEAED;
    cursor: pointer; }
  .Payment {
    display: flex;
    align-items: center;
    .PaymentTitle {
      font-size: 12px;
      margin-right: 4px; }
    .Cash {
      background-color: #FFCF53; } }
  .Options {
    position: relative;
    .OptionsBox {
      border-radius: 4px;
      right: 0;
      position: absolute;
      padding: 8px;
      background-color: #fff;
      z-index: 1;
      box-shadow: var(--boxShadow);
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;
      min-width: 100px;
      justify-items: start; } } }
.EventTile_theme_confirmed {
  background-color: rgba(#FF9600, 0.05);
  border: 1px var(--accent2) solid; }
.EventTile_theme_booked {
  background-color: rgba(#C3DFE6, 0.2);
  border: 1px #C3DFE6 solid; }
.EventTile_theme_group {
  border: 1px #c01ded solid; }
