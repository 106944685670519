.Field-DateRange {
  position: relative;
  padding: 0px 12px;
  border-radius: 8px;
  input {
    padding-left: 0;
    padding-right: 0; }
  label {
    left: 20px; }
  .TaskDatePicker {
    position: absolute;
    width: auto;
    top: 48px; } }
